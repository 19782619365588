export const wxShareMixin = {
    methods: {
        wxShare() {
            let data = location.href;
            let httpRequest = new XMLHttpRequest();//第一步：创建需要的对象
            httpRequest.open('POST', 'https://admin.hezijiyin.com/public/api.php/wechat/index/shareJssdk', true); //第二步：打开连接
            httpRequest.setRequestHeader("Content-type","application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
            httpRequest.send('url=' + data);//发送请求 将情头体写在send中
            /**
             * 获取数据后的处理程序
             */
            httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
                if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                    let json = httpRequest.responseText;//获取到服务端返回的数据
                    let wx_config = JSON.parse(json);
                    wx.config(wx_config);
                }

            }
        },
        // 设置分享的相关内容
        wxShareMessage(title, desc, imgUrl) {
            wx.ready(function () {
                wx.updateAppMessageShareData({
                    title: title,
                    desc: desc,
                    link: location.href,
                    imgUrl: location.origin + imgUrl,
                    success() {
                        // console.log('success')
                    }
                })
            })
            wx.error(function (res) {
                // console.log(res)
            })
        }
    }
}
