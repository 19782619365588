<template>

<div>
  <div class="detail">
    <img src="~assets/img/HPV/hpv.jpg" alt="">
  </div>
  <div class="code" v-show="isShow" @touchmove.prevent>
    <img src="~assets/img/code.png" alt="" @click="imgClick">
  </div>
  <div class="bottom">
    <div class="click" @click="bottomClick">
      预约咨询
    </div>
  </div>
</div>

</template>

<script>
  import { wxShareMixin } from "common/mixin";
  export default {
    name: "HPV",
    data() {
      return {
        isShow: false
      }
    },
    mixins: [wxShareMixin],
    created() {
      this.wxShare()
      this.wxShareMessage('HPV分型基因检测',
          'HPV中文名称人乳头瘤病毒，一种小DNA病毒，能引起人类皮肤和黏膜的多种良性乳头状瘤...',
          '/img/shengwu.png')
    },
    methods: {
      bottomClick() {
        this.isShow = !this.isShow
      },
      imgClick() {
        this.isShow = !this.isShow
      }
    }
  }
</script>

<style scoped>
  .detail{
    margin-bottom: 9%;
    font-size: 0;
    padding-bottom: 5%;
  }
  .detail img {
    width: 100%;
  }
  .code{
    background-color:rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /*width: 56vh;*/
    height: 100%;
    z-index: 99;
  }
  .code img{
    width: 80%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom{
    height: 9%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .click{
    /*background-color: #d16061;*/
    background:linear-gradient(to right,#ea5862,#d0736e);
    width: 55%;
    height: 66%;
    color: #ffffff;
    font-size: 2.5vh;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 80px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
</style>